
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    import {html} from 'lit';
    import {str} from '@lit/localize';

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      'h4e032a9ba1fe5753': html`Ich lese meine Konfiguration aus: <code>${0}</code>`,
'h4ecc1b3d116a58d7': html`Ich warte darauf, dass mein <code>${0}</code> Attribut definiert wird`,
'hab2fdbba66c93e40': html`Konfiguration wird von <code>${0}</code> abgerufen`,
's032e92c80863cc5a': str`asynchron läuft`,
's037df8d2f49aa980': str`geladen`,
's6ed675fff1cc77ed': str`Dinge, die ich dem Komponenten-Unterbaum zur Verfügung stelle:`,
's84ae32ba760890f9': str`Oh, ich bin die App-Shell!`,
's9e9b04c54923ae13': str`inaktiv`,
's9f7452dd75d54d31': str`fehler`,
'seac9afef1a00b5e8': str`abwartend`,
'sf48d916fa3ea8dd7': str`Sprache:`,
'sfa124b47e896b2aa': str`Wartet darauf, dass der Ausstehend-Status definiert wird`,
    };
  